import React from "react";
import { Container, Typography, Box, Divider, Paper } from "@mui/material";
import { getFirmContact, getFirmEmail } from "../theme/setThemeColor";

function ContactUs() {
  return (
    <Container>
      <Box my={4} sx={{ textAlign: "center" }}>
        {/* Header with custom style */}
        <Typography
          variant="h2"
          color="primary"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Contact Us
        </Typography>

        {/* Contact Details Box */}
        <Paper elevation={3} sx={{ padding: 3, backgroundColor: "#f5f5f5" }}>
          <Box
            mt={4}
            sx={{ backgroundColor: "#FF5733", borderRadius: 2, padding: 2 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#fff", textAlign: "left" }}
            >
              Customer Care No :
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#fff", // Custom color code
                fontWeight: "bold", // Bold text
                fontFamily: "Roboto, sans-serif", // Custom font (Roboto)
                fontSize: "26px", // Custom font size
                textAlign: "center", // Centering text
              }}
            >
              {getFirmContact()}
              {/* Center the contact number */}
            </Typography>
          </Box>

          <Box
            mt={4}
            sx={{ backgroundColor: "#4CAF50", borderRadius: 2, padding: 2 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#fff", textAlign: "left" }}
            >
              Email Id :
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body1"
                sx={{
                  color: "#fff", // Custom color code
                  fontWeight: "bold", // Bold text
                  fontFamily: "Roboto, sans-serif", // Custom font (Roboto)
                  fontSize: "26px", // Custom font size
                }}
              >
                {getFirmEmail()}{" "}
                {/* Assuming this function returns the email */}
              </Typography>
            </Box>
          </Box>

          <Box
            mt={4}
            sx={{ backgroundColor: "#FF9800", borderRadius: 2, padding: 2 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#fff", textAlign: "left" }}
            >
              Billing Number :
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#fff", // Custom color code
                fontWeight: "bold", // Bold text
                fontFamily: "Roboto, sans-serif", // Custom font (Roboto)
                fontSize: "26px", // Custom font size
              }}
            >
              {/* {getFirmContact()}{" "} */}
              {/* Assuming this could be the same contact number */}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default ContactUs;
